
import { Component, Vue } from 'vue-property-decorator'
const BreadCrumb = () => import('../components/BreadCrumbs.vue')
@Component({
  components: { BreadCrumb }
})
export default class WhereToBuy extends Vue {
  dealers = []
  async created() {
    this.$axios.get('/api/utilities/dealerlist').then((res) => (this.dealers = res.data))
  }
}
